<template lang="pug">
  tr.table__row.table__row--body
    td.table__cell.table__cell--medium {{ housing.name }}
    td.table__cell {{ housing.silages_list | liststr('number') }}
    td.table__cell
      .table__cell-wrapper
        p.table__cell-text {{ housing.comment }}
        v-btn.table__button.table__button--settings-left(
          icon
          v-permission-hide="permissions.update_housing"
          @click="modals.showSettings = true")
          icon-gear.table__settings
        v-dialog(
          max-width="348"
          v-model="modals.showSettings")
          settings-modal(
            :item="housing"
            @close="modals.showSettings = false")
</template>

<script>
import IconGear from '@/components/icons/IconGear'
import SettingsModal from './modals/SettingsModal'
import permissions from '@/util/permissions'

export default {
  name: 'BuildingsTableRow',

  props: {
    housing: {
      type: Object,
      required: true
    }
  },

  components: {
    IconGear,
    SettingsModal
  },

  data: () => ({
    modals: {
      showSettings: false
    },
    permissions: permissions
  })
}
</script>

<style lang="scss" scoped>
</style>
