import { render, staticRenderFns } from "./BuildingsTableRow.vue?vue&type=template&id=6e3d4bd6&scoped=true&lang=pug"
import script from "./BuildingsTableRow.vue?vue&type=script&lang=js"
export * from "./BuildingsTableRow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3d4bd6",
  null
  
)

export default component.exports