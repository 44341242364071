<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.buildings.settings.title' | translate }}
    v-card-content
      v-text-field.mb-3(
        :label="$t('base.name')"
        v-model="form.name")
      v-text-field(
        :label="$t('base.desc')"
        v-model="form.info")
    v-card-actions
      v-btn(
        :disabled="!isNameValid"
        :loading="loading"
        @click="onUpdate").mr-2 {{ 'actions.save' | translate }}
      delete-btn(
        v-permission-hide="permissions.delete_housing"
        @delete="onDelete")
</template>

<script>
import DeleteBtn from '@/components/common/delete-btn/DeleteBtn'
import permissions from '@/util/permissions'

export default {
  name: 'SettingsModal',

  components: {
    DeleteBtn
  },

  props: {
    item: Object
  },

  data: () => ({
    form: {
      name: null,
      info: null
    },
    loading: false,
    permissions: permissions
  }),

  computed: {
    isNameValid () {
      return !!this.form.name
    }
  },

  mounted () {
    this.form.name = this.item.name
    this.form.info = this.item.comment
  },

  methods: {
    async onUpdate () {
      this.loading = true
      const payload = {
        name: this.form.name,
        comment: this.form.info
      }
      const { data, error } = await this.$store.dispatch('updateHousing', { id: this.item.id, payload })
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(this.$t(`errors.${key}.${msg}`), {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('close')
      }
    },

    async onDelete () {
      const { data, error } = await this.$store.dispatch('deleteHousing', { id: this.item.id })
      if (error) {
        for (const key in data.errors) {
            this.$toasted.show(data.errors[key], {
              position: 'bottom-center',
              duration: 5000
            })
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
