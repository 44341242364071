<template lang="pug">
  .table-layout
    table.table.table--cell-overflow-hidden
      thead.table__header
        tr.table__row.table__row--header
          th.table__cell {{ 'base.name' | translate }}
          th.table__cell {{ 'pages.settings.silages_list' | translate }}
          th.table__cell {{ 'base.desc' | translate }}
      v-table-body(
        :cols="3"
        :loading="loading"
        :empty="!items.length")
        buildings-table-row(
          v-for="housing in items"
          :key="housing.id"
          :housing="housing")
      tfoot.table__footer(
        v-if="!loading && showPagination")
        tr.table__row.table__row--footer
          td.table__cell(colspan="5")
            .table__pagination
              v-paginate(
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageChangeHandler")

</template>

<script>
  import { mapActions } from 'vuex'

  import paginationMixin from '@/mixins/pagination.mixin'
  import BuildingsTableRow from './BuildingsTableRow'

  export default {
    name: 'BuildingsTable',

    mixins: [paginationMixin],

    components: {
      BuildingsTableRow
    },

    mounted () {
      this.setupPagination(this.fetchHousings)
    },

    methods: {
      ...mapActions(['fetchHousings'])
    }
  }
</script>

<style lang="scss" scoped>
</style>
